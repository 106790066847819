import styled from "styled-components";
import { Link } from "react-router-dom";

const Inputs = styled.div`
  display: flex;
  align-item: center;
  color: #374151;
  gap: 10px;
  font-size : 15px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }

  > label {
    font-weight: 600;
    min-width: 100px;
    margin-right: 15px;
    > span {
      color: red;
    }
  }

  > input {
    border: 1px solid #cbd5e1;
    border-radius: 5px;
    padding: 5px 8px;
    width: 100%;
    max-width: 700px;

    &:focus {
      outline: 1px solid #94a3b8;
    }
  }
`;

const TreeForms = () => {
  return (
    <div className='py-20 lg:p-20 '>
      <div className='mb-7 mx-auto w-full max-w-4xl '>
      <ul className='steps w-full max-w-4xl '>
        <li className='step '></li>
        <li className='step step-primary'></li>
        <li className='step'></li>
      </ul>
      </div>
      <p className='mb-7 font-bold border-b border-solid border-base-200 pb-4 keepAll'>
        나무 표식에 들어갈 내용을 입력해주세요.
      </p>
      <div>
        <form className='space-y-5'>
          <Inputs>
            <label>
              나무 심는 사람<span>*</span>
            </label>
            <input type='text' placeholder='개인 및 단체 이름/별명' required />
          </Inputs>
          <Inputs>
            <label>
              식수 기념 날짜<span>*</span>
            </label>
            <input type='text' placeholder='날짜를 적어주세요' required />
          </Inputs>
          <Inputs>
            <label>
              식수 기념 내용<span>*</span>
            </label>
            <input type='text' placeholder='ex) 결혼 기념일 10주년' required />
          </Inputs>
          <Inputs>
            <label>
              추가내용<span>*</span>
            </label>
            <input
              type='text'
              maxLength={100}
              placeholder='나무 표식에 추가할 내용이 있다면, 100자 이내로 작성해주세요.'
              required
            />
          </Inputs>
        </form>
      </div>

      <div>
        <p className='mt-10 mb-7 font-bold border-b border-solid border-base-200 pb-4 '>
          식수 기념 키트를 받을 주소와 정보를 입력해주세요.
        </p>
        <form className='space-y-3'>
          <Inputs>
            <label>
              받는 사람<span>*</span>
            </label>
            <input type='text' required />
          </Inputs>
          <Inputs>
            <label>
              받는 주소<span>*</span>
            </label>
            <input type='text' required />
          </Inputs>
          <Inputs>
            <label>
              연락처<span>*</span>
            </label>
            <input type='text' placeholder='ex) 010-1234-5678' required />
          </Inputs>
        </form>

        <div className='flex flex-col items-end gap-5 mt-8 font-semibold'>
          <p>* 신청 내용 확인 후, 개별 연락을 통해 최종 확정됩니다.</p>
          <Link to='/tree_success'>
            <button className='bg-success text-white w-32 py-1 transition duration-300 ease-in-out hover:bg-secondary'>
              다음
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default TreeForms;
