const Loading = () => {
  return (
    <div className='w-full h-100vh flex flex-col justify-center items-center bg-loading text-neutral'>
        <div className="coffee cofeeAnimation">
            <div></div>
            <div></div>
            <div></div>
        </div>
      <p className="text-md mt-2 font-semibold">Loading</p>
    </div>
  );
};

export default Loading;
