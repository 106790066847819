import {useRef, useEffect} from 'react';
import Layout from "../../Layout";
import KakaoMap from "./KakaoMap";
import PageHeader from "../../Common/PageHeader";
import {gsap} from 'gsap';

const WelcomeMap = () => {

  const el = useRef(null);
  const q = gsap.utils.selector(el);
  useEffect(()=>{
    gsap.fromTo(q('.animation'), {opacity: 0}, {
      opacity: 1,
      duration: 1,
      stagger: 0.3
    });
  },[])

  return (
    <Layout>
      <div className='w-full min-h-screen font-serif'>
          <PageHeader PageTitle={'오시는 길'} />
        <div ref={el} className='w-full max-w-7xl my-0 mx-auto py-20 px-8 lg:p-20'>
          <div className='animation flex flex-col md:flex-row text-md text-gray-700 mb-1 font-medium justify-between'>
            <p>강원도 강릉시 구정면 어단리995~2(금평로210 ~59) </p>
            <p>OOO-OOO-OOOO</p>
          </div>
          <KakaoMap/>
          <div className='max-w-5xl space-y-5 py-12'>
            <div className='leading-8 text-gray-700 pb-5 border-b border-solid border-gray-200'>
              <h4 className='font-semibold'>자가용 이용</h4>
              <p>
                남강릉IC에서 남강릉, 정동진 방면 진출 및 남강릉 톨게이트 통과
                후, 어단 교차로에서 ‘어단리, 법왕사’ 방면으로 우회전. 이어서
                금평로 방면으로 우회전.
              </p>
            </div>
            <div className='leading-8 text-gray-700 pb-5 border-b border-solid border-gray-200'>
              <h4 className='font-semibold'>기차 이용</h4>
              <p>
                강릉역(강원 강릉시 용지로 176)에서 하차 후, 강릉역
                버스정류장에서 101번 승차. 대성사 입구에서 하차 후, 약 700m
                도보로 직진.
              </p>
            </div>
            <div className='leading-8 text-gray-700 pb-5 border-b border-solid border-gray-200'>
              <h4 className='font-semibold'>시외버스/고속버스 이용</h4>
              <p>
                강릉시외터미널(강원 강릉시 하슬라로 27 강릉시외버스터미널) 혹은
                강릉고속터미널(강원 강릉시 하슬라로 15 고속버스터미널)에서 하차
                후, 시외/고속터미널에서 101번 승차. 대성사 입구에서 하차 후, 약
                700m 도보로 직진.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default WelcomeMap;
