import Header from "../Layout/Header";
import Footer from "../Layout/Footer";

const Layout = ({children}) => {

    return(
    <>
        <Header/>
        {children}
        <Footer/>
        </>
    )
}

export default Layout;