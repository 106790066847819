import { useRef, useLayoutEffect } from "react";
import Layout from "../../Layout";
import PageHeader from "../../Common/PageHeader";
import ProgramCards from "../../Common/ProgramCards";
import { gsap } from "gsap";

const ProgramInfo = () => {
  const el = useRef(null);
  const q = gsap.utils.selector(el);

  useLayoutEffect(() => {
    gsap.fromTo(
      q(".boxes"),
      {
        opacity: 0,
      },
      {
        opacity: 1,
        ease: "power1",
        duration: 3,
        stagger: 0.5,
      }
    );
  }, []);

  return (
    <Layout>
      <PageHeader PageTitle={"프로그램"} />
      <div
        ref={el}
        className='px-8 py-10 lg:px-16 lg:py-28 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 max-w-screen-xl mx-auto my-0 font-serif'
      >
        <ProgramCards
          image={"/9.jpg"}
          title={"인생 나무 심기"}
          description={
            "탄생, 입학, 졸업, 취업, 결혼, 출산, 은퇴, 죽음 등 살아가면서 맞이하는 주요 사건들을 기념하기 위해 성격에 맞는 나무를 정원 내에 심을 수 있습니다. 나무에는 이름, 내용을 담은 표식을 달아드립니다."
          }
        />

        <ProgramCards
          image={"/12.jpg"}
          title={"사랑의 타임캡슐"}
          description={
            "연인/친구/가족 등의 영원한 사랑과 우정을 약속하는 자물쇠를 걸고, 5년 후에 개봉할 수 있는 편지를 서로에게 써서 보관할 수 있습니다. 개봉 시기인 5년 후 편지를 작성한 연인/친구/가족이 재방문할 경우, 기념 선물을 증정합니다."
          }
        />
        <ProgramCards
          image={"/3.jpg"}
          title={"포레스트 콘서트"}
          description={
            "월 1회 주말 혹은 금요일 저녁에 연극, 뮤지컬, 음악회, 토크 콘서트, 북 콘서트 등 다양한 형태의 소규모 공연을 개최합니다."
          }
        />

        <ProgramCards
          image={"/10.jpg"}
          title={"포레스트 파티"}
          description={
            "탄생, 입학, 졸업, 취업, 결혼, 출산, 은퇴, 죽음 등 살아가면서 맞이하는 주요 사건들을 기념할 수 있는 소규모 행사를 대관해서 진행할 수 있습니다. 음식점, 기념 사진 촬영, 무대 활용 등이 가능합니다. 원하시면, 파티 참석자들에게 기념일의 성격에 맞는 화분/씨앗을 증정할 수 있도록 준비해드립니다."
          }
        />

        <ProgramCards
          image={"/11.jpg"}
          title={"인생 보물찾기 워크숍"}
          description={
            "생애주기에 따라 설계된 정원을 걸으면서 곳곳에 숨겨진 인생 보물(도토리, 솔방울, 밤, 돌멩이 등)을 찾을 수 있습니다. 찾은 보물로 생일카드 제작, 선물 제작 등 인생에서 기념할 만한 날을 축하하기 위한 것들을 제작하는 활동을 할 수 있습니다."
          }
        />

        <ProgramCards
          image={"/7.jpeg"}
          title={"피크닉 패키지 대여"}
          description={
            "정원 내에서 피크닉을 즐길 수 있도록 돗자리, 담요, 음료, 간식, 스피커 등이 담긴 바구니를 대여합니다. 바구니는 정원 이용 중에 사용하실 수 있고 다 사용하신 후에는 반납하시면 됩니다"
          }
        />
      </div>
    </Layout>
  );
};

export default ProgramInfo;
