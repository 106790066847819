const PageHeader = ({ PageTitle }) => {
  return (
    <div className={`h-60 max-h-full flex items-center justify-center`} >
      <h2 className='font-serif font-semibold text-2xl text-slate-700 pt-9 pb-3 border-b border-solid border-slate-200 inline-block'>
        {PageTitle}
      </h2>
    </div>
  );
};

export default PageHeader;
