import { useLayoutEffect } from 'react';
import { gsap } from 'gsap';

const HeroMain = () => {

  useLayoutEffect(() => {
    gsap.to(".InteractiveText", {
      opacity: 1,
      stagger: 0.3,
      duration: 5
    });

    
    gsap.to(".InteractiveText2", {
      opacity:1,
      stagger:0.5,
      duration: 3
    })

  }, []);

  return (
    <section>
      <div className="hero min-h-screen" style={{ backgroundImage: `url("forest-carpathian-mountains-autumn-day.jpg")` }}>
        <div className="hero-overlay bg-opacity-60"></div>
        <div className="hero-content text-center text-neutral-content">
          <div className="max-w-md font-serif">
            <h1 className="mb-5 text-5xl font-bold  InteractiveText opacity-0">Cestlavie garden</h1>
            <div className="mb-5  InteractiveText2 opacity-0">
              <p>우리는 모두 자연에서 와서 자연으로 돌아갑니다.</p>
              <p>We all come from and go back to nature.</p>
            </div>
            <a href='/home' className="cursor-pointer">
          <button className="btn font-serif  InteractiveText2 opacity-0"> 깊은 숲 속으로</button>
          </a>
          </div>
        </div>
      </div>
    </section>
  )
}

export default HeroMain;