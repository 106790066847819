
const Footer = () => {
    return(
        <footer className="footer h-full p-10 bg-gray-100 text-base-content justify-between z-50">
        <div>
          <span className="footer-title">주식회사 사랑나무</span> 
          <p className="">강원도 강릉시 구정면 어단리995~2(금평로210 ~59)</p> 
          <p className="">000-0000-0000</p> 
        </div> 
        <div>
          <span className="footer-title">강릉 세라비 정원 운영시간</span> 
          <p className="">평일 : 00:00 ~ 00:00</p> 
          <p className="">주말 : 00:00 ~ 00:00</p>           
          <p className="mt-3">@사랑나무 All Rights are Reserved</p>
        </div> 
        <div>
        <picture className='flex flex-row items-center gap-2 font-serif'>
            <source media="(max-width: 480px)" width={'40'} height={'100%'} alt='푸터로고' srcSet="/titleLogo.png"/>
            <source width={'40'} height={'100%'} alt='푸터로고' srcSet="/titleLogo.png" />
            <img
                srcSet='/titleLogo.png'
                sizes="(max-width: 480px), 40px" 
                alt='푸터로고'
                width={'40'}
                height={"100%"}
              />
               <div className="text-center">
            <p className="text-primary font-bold text-lg font-color-green">Cestlavie garden</p>
            <p className='text-xs'>세라비 정원</p>
          </div>
          </picture>
            
        </div>
      </footer>
    )
}

export default Footer;