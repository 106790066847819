

export const CardContainer = ({children}) => {
    return(
        <div 
        className='
        max-w-xl 
        p-5 
        bg-stone-50 
        h-full 
        rounded-xl'>
            {children}
        </div>
    )
}

export const CardHeader = ({children}) => {
    return(
        <h4 className='font-bold text-lg mb-2 border-b border-solid border-stone-300 pb-3'>
            {children}
        </h4>
    )
}