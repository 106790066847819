const ProgramCards = ({ image, title, description }) => {
  return (
    <div className='shadow-lg flex flex-col boxes'>
      <img
        className='w-full max-h-96'
        src={image}
        alt={title}
        width={250}
        height={300}
      />
      <div className='px-6 py-10 h-full flex flex-col justify-between'>
        <div className='mb-5'>
          <h3 className='text-lg font-bold mb-2'>{title}</h3>
          <p>{description}</p>
        </div>
        <div className='text-right justify-end'>
          <button className='bg-gray-200 font-semibold text-black px-4 py-3 rounded-2xl outline-none'>
            더보기
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProgramCards;
