import Layout from "../../../Layout";
import { Link } from "react-router-dom";

const TreeSuccess = () => {
  return (
    <Layout>
      <div className='h-full min-h-screen flex px-4 flex-col gap-8 justify-center items-center '>
        <ul className='steps mb-7 w-full max-w-4xl mx-auto'>
          <li className='step '></li>
          <li className='step'></li>
          <li className='step  step-primary'></li>
        </ul>
        <h3 className='font-bold text-xl keepAll lg:text-3xl leading-normal text-center'>
          기억나무심기 신청이 완료되었습니다!🥳 
        </h3>
        <Link to='/home'><button className='btn btn-primary'>홈으로 가기</button></Link>
      </div>
    </Layout>
  );
};

export default TreeSuccess;
