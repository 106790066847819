import Layout from "../../../Layout";
import { useRef, useState } from "react";
import styled from "styled-components";
import PageHeader from "../../../Common/PageHeader";
import TreeCards from "./TreeCards";
import TreeForms from "./TreeForms";
import { FaMouse } from "react-icons/fa";
import { CardContainer, CardHeader } from "../../../Common/CustomCard";

const FixedLine = styled.div`
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  color: #444;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  > div {
    width: 0px;
    height: 80px;
    border: 1px solid #444;
  }
`;

const MemoryTree = () => {

  const growRef = useRef();
  const contentRef = useRef();
  const formRef = useRef();


  const ScrollDown = () => {
    console.log(formRef);
    formRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  };


  const goDown = (e) => {
    contentRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  };

  return (
    <Layout>
      <PageHeader PageTitle={"기억나무 심기"} />
      <div className="max-w-screen-xl mx-auto my-0">
        <div
          data-aos='fade-zoom-in'
          data-aos-delay='300'
          data-aos-duration='1200'
        >
          <div
            className='px-6 pt-20 text-neutral keepAll  font-serif'
          >
            <h2 className='font-bold text-xl mb-3'>
              기억 한 그루를 심다
            </h2>
            <div className='font-semibold leading-9'>
              <p>탄생과 죽음, 사랑과 이별, 시작과 끝.</p>
              <p>살아가면서 기념하고 싶은 수많은 일들을 오랫동안 기억하기 위해 한 그루 나무를 심어보세요.</p>
            </div>
          </div>
          <div
            className='grid grid-cols-2 px-8 py-20 font-serif gap-8'
          >
            <CardContainer>
              <CardHeader>나무 성장 보고서</CardHeader>
              <p>
                월 1회 정원의 관리 내역(가지치기, 거름 등)및 성장 사진을 포함한
                나무의 성장 소식을 이메일로 보내드립니다.
              </p>
            </CardContainer>
            <CardContainer>
              <CardHeader>소원/약속 걸기</CardHeader>
              <p>
                식수한 나무에 소원이나 약속을 담은 판이나 자물쇠를 걸어 정원에
                방문할 때마다 기억할 수 있습니다.
              </p>
            </CardContainer>
            <CardContainer>
              <CardHeader>워크숍 프로그램</CardHeader>
              <p>
                식수한 나무의 열매, 가지, 잎 등을 활용할 수 있는 요리, 예술 작품을
                창작 활동, 가지를 삽목하여 화분으로 만드는 활동 등을 함께 합니다
              </p>
            </CardContainer>
            <CardContainer>
              <CardHeader>정원 방문 및 이용</CardHeader>
              <p>
                매년 식수 일자를 기념해 정원에 무료 입장 혜택을 드리고, 정원 내
                시설 대관 및 프로그램 이용 시 할인 혜택을 드립니다.
              </p>
            </CardContainer>
          </div>
          <div className="text-center">
            <button
              onClick={goDown}
              className='border border-solid border-primary text-primary bg-base-100 transition-all ease-in-out duration-300 hover:bg-primary hover:text-base-100 px-4 py-2 rounded-2xl font-semibold cursor-pointer'
            >
              나만의 나무 심으러 가기
            </button>
          </div>

        </div>



        <div
          ref={contentRef}
          data-aos='fade-zoom-in'
          data-aos-delay='200'
          data-aos-duration='1200'
          className='px-6 py-20 md:p-20 flex flex-col justify-center align-center text-neutral gap-5 h-100vh  bg-base-100 opacity-0 relative keepAll  font-serif'
        >
          <h2 className=' text-center font-bold text-xl'>
            언제나 숨 쉬는 기억으로
          </h2>
          <div className='text-center font-semibold text-lg leading-9'>
            <p>나무가 성장하면서 1주년, 2주년 해를 거듭해</p>
            <p>살아있는 기억으로 함께 할 수 있습니다</p>
            <p>나무의 긴 생애주기를 따라</p>
            <p>기념해야 할 일을 더 뜻깊게 기념해보세요.</p>
          </div>

          <FixedLine >
            <FaMouse color='#444' size={20} />
            <p>Scroll</p>
            <div></div>
          </FixedLine>

        </div>

        <div ref={growRef}>
          <div
            data-aos='fade-zoom-in'
            data-aos-delay='100'
            data-aos-duration='1200'
            className='flex flex-col justify-center align-center text-neutral gap-5 h-full  bg-base-100 opacity-0 relative'
          >
            {/* 선택 */}
            <div className='px-10 py-20 lg:p-20 flex flex-col'>
              <ul className='steps mb-7 mx-auto w-full max-w-4xl'>
                <li className='step step-primary'></li>
                <li className='step'></li>
                <li className='step'></li>
              </ul>

              <p className='mb-5'>
                나무의 생김새, 특성을 확인한 후 아래에서 나무 종류를 선택해주세요.
              </p>
              <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4'>
                <TreeCards
                  ScrollDown={ScrollDown}
                  src='/tree.png'
                  altText='나무1'
                  treeName='기억나무1'
                  description='기억나무 설명이 들어갈 예정입니다. 나무의 생김새 특성들이 들어갈 예정입니다. 위에는 나무 이미지, 이름 등이 나타납니다.'
                />
                <TreeCards
                  ScrollDown={ScrollDown}
                  src='/tree2.png'
                  altText='나무2'
                  treeName='기억나무2'
                  description='기억나무 설명이 들어갈 예정입니다. 나무의 생김새 특성들이 들어갈 예정입니다. 위에는 나무 이미지, 이름 등이 나타납니다.'
                />
                <TreeCards
                  ScrollDown={ScrollDown}
                  src='/tree3.png'
                  altText='나무3'
                  treeName='기억나무3'
                  description='기억나무 설명이 들어갈 예정입니다. 나무의 생김새 특성들이 들어갈 예정입니다. 위에는 나무 이미지, 이름 등이 나타납니다.'
                />
                <TreeCards
                  ScrollDown={ScrollDown}
                  src='/tree4.png'
                  altText='나무4'
                  treeName='기억나무4'
                  description='기억나무 설명이 들어갈 예정입니다. 나무의 생김새 특성들이 들어갈 예정입니다. 위에는 나무 이미지, 이름 등이 나타납니다.'
                />
              </div>
              <div ref={formRef}>
                <TreeForms />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default MemoryTree;
