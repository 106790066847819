
const ThemeText = ({title, content}) => {
    return(
        <div
        className='px-8 py-10 leading-8 max-w-4xl 2xl:max-w-6xl text-lg text-neutral'
        data-aos='fade-zoom-in' 
        data-aos-delay='100'
        data-aos-duration='1200'
      >
        <b>{title} | </b>{content}
      </div>
    )
}

export default ThemeText;