import { Suspense, lazy } from "react";
import { Routes, Route } from "react-router-dom";
import AboutUs from "./Components/Introduce/AbousUs";
import AllGuideMap from "./Components/Introduce/AllGuideMap";
import MemoryTree from "./Components/Introduce/MemoryTree";
import TreeSuccess from "./Components/Introduce/MemoryTree/TreeSuccess";
import ThemeGarden from "./Components/Introduce/ThemeGarden";
import ProgramInfo from "./Components/UseInfo/ProgramInfo";
import Reservation from "./Components/UseInfo/Reservation";
import VisitGuide from "./Components/VisitingInfo/VisitGuide";
import WelcomeMap from "./Components/VisitingInfo/WelcomeMap";
import Loading from "./Common/Loading";
import useScrollToTop from "./Common/ScrollToTop";
import HeroMain from "./Components/Home/HeroMain";

function App() {
  const Home = lazy(() => import("./Components/Home"));
  useScrollToTop();

  return (
    <Suspense fallback={<Loading />}>
      <Routes>
        <Route path='/' element={<HeroMain/>} />
        <Route path='/home' element={<Home />} />
        <Route path='/about' element={<AboutUs />} />
        <Route path='/memorytree' element={<MemoryTree />} />
        <Route path='tree_success' element={<TreeSuccess />} />
        <Route path='/guidemap' element={<AllGuideMap />} />
        <Route path='/theme' element={<ThemeGarden />} />
        <Route path='/program' element={<ProgramInfo />} />
        <Route path='/reserve' element={<Reservation />} />
        <Route path='/roadmap' element={<WelcomeMap />} />
        <Route path='/visit' element={<VisitGuide />} />
      </Routes>
    </Suspense>
  );
}

export default App;
