import styled from 'styled-components';

const Inputs = styled.div`

display:flex;
flex-direction: column;
gap: 8px;

align-item : center;
justify-content : space-between;
color: #374151;

> label {
    font-weight: 600;
    margin-right: 15px;
    > span {
        color : red;
    }
}

> input {
    border: 1px solid #cbd5e1;
    border-radius : 5px;
    padding: 5px 8px;
    width: 100%;
    max-width: 900px;

    &:focus { outline: 1px solid #94a3b8; }
}

`
const ReserveForm = () => {
    return(
        <div >
        <form className='space-y-4'>
            <Inputs>
                <label>신청 프로그램<span>*</span></label>
                <input type="text" placeholder="신청할 프로그램을 적어주세요." required/>
            </Inputs>
            <Inputs>
                <label>신청자명<span>*</span></label>
                <input type="text" placeholder="이름을 적어주세요." required/>
            </Inputs>
            <Inputs>
                <label>방문일자<span>*</span></label>
                <input type="text" placeholder="방문일자를 적어주세요." required/>
            </Inputs>
            <Inputs>
                <label>방문인원<span>*</span></label>
                <input type="text" placeholder="방문인원을 적어주세요" required/>
            </Inputs>
            <Inputs>
                <label>연락처<span>*</span></label>
                <input type="text" placeholder="연락처를 적어주세요" required/>
            </Inputs>
            <Inputs>
                <label>입금자명<span>*</span></label>
                <input type="text" placeholder="입금자명을 적어주세요" required/>
            </Inputs>
        </form>
        <div className='text-right mt-7'>
        <button className='btn btn-success text-white w-48'>예약하기</button>
        </div>
    </div>
    )
}

export default ReserveForm;