import React, {useState} from "react";
import { Link } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import MobileHeader from "./MobileHeader";

const Header = () => {
  const [onToggle, setToggle] = useState(false);
  return (
    <header
      className={`bg-slate-50 font-serif py-1 px-4 lg:px-8 sticky top-0 z-50 w-full`}
    >
      <div className='navbar w-full'>
        <div className='flex-1'>
          <a href='/home' className='flex flex-row items-center gap-2'>
          <picture>
            <source media="(max-width: 480px)" width={'40'} height={'100%'} alt='로고' srcSet="/titleLogo.png"/>
            <source width={'60'} height={'100%'} alt='로고' srcSet="/titleLogo.png" />
            <img
                srcSet='/titleLogo.png'
                sizes="(max-width: 480px), 40px" 
                alt='로고'
                width={'60'}
                height={"100%"}
              />
          </picture>
          <div className="text-center">
            <p className="text-primary font-bold text-lg font-color-green">Cestlavie garden</p>
            <p className='text-xs'>세라비 정원</p>
          </div>
          </a>
        </div>
        <div className='flex-none'>
          {/* 모바일 아이콘 */}
          <GiHamburgerMenu width='2em' height='2em' onClick={()=> setToggle(!onToggle)} className='transition duration300 ease-in-out cursor-pointer md:hidden' color="#444" />

          {onToggle && (
            <MobileHeader setToggle={setToggle}/>
          )}

          <ul className='hidden md:flex menu menu-horizontal p-0 space-x-12 '>
            <li className='  '><Link to='/home'>Home</Link></li>
            <li tabIndex='0'>
              <span className=''>
                Intro
              </span>
              <ul className='bg-base-100 z-10 border border-slate-300 rounded-sm'>
                <li >
                  <Link
                    to='/about'
                    className=''
                  >
                    정원소개
                  </Link>
                </li>
                <li className="">
                  <Link
                    to='/guidemap'
                    className=''
                  >
                    종합안내도
                  </Link>
                </li>
                <li>
                  <Link
                    to='/theme'
                    className=''
                  >
                    테마정원
                  </Link>
                </li>
              </ul>
            </li>

            <li tabIndex='1'>
              <span className='  '>
                Program
              </span>
              <ul className= 'bg-base-100 z-10 border border-slate-300 rounded-sm'>
                 <li>
                  <Link
                    to='/memorytree'
                    className=''
                  >
                    기억나무 심기
                  </Link>
                </li>
                <li className="">
                  <Link
                    to='/program'
                    className=''
                  >
                    주요 서비스
                  </Link>
                </li>
                <li className="">
                  <Link
                    to='/reserve'
                    className=''
                  >
                    방문예약
                  </Link>
                </li>
              </ul>
            </li>

            <li tabIndex='2'>
              <span className='  '>
                Guide
              </span>
              <ul className= 'bg-base-100 z-10 border border-slate-300 rounded-sm'>
                <li>
                  <Link
                    to='/roadmap'
                    className=''
                  >
                    오시는 길
                  </Link>
                </li>
                <li className="">
                  <Link
                    to='/visit'
                    className=''
                  >
                    이용안내
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
};

export default Header;
