import { useEffect } from "react";

const KakaoMap =() => {
  const { kakao } = window;

  const Map = () => {
    let container = document.getElementById("map");

    let options = {
      center: new kakao.maps.LatLng(37.697641,128.8904441),
      level:2,
    };

    let map = new kakao.maps.Map(container, options);

    let marker = new window.kakao.maps.Marker({
      position: options.center,
    });
    marker.setMap(map);
  };

  useEffect(() => {
    Map();
  }, []);

  return (
    <div className='animation Map'>
      <div style={{ width: "100%", height: "300px" }} id='map'></div>
    </div>
  );
};

export default KakaoMap;
