import Layout from "../../Layout";
import PageHeader from "../../Common/PageHeader";

const VisitGuide = () => {
  return (
    <Layout>
      <div className={`w-full h-full font-serif min-h-screen`}>
          <PageHeader PageTitle={'이용 안내'} />
        <div className='prose py-28 px-8 lg:px-24  prose-lg max-w-5xl space-y-6 keepAll' 
          data-aos='fade-zoom-in'
          data-aos-delay='300'
          data-aos-duration='1200'
        >
          <h1 className={`text-xl text-gray-700 mt-6 font-semibold`}>입장료</h1>
          <p>
            레스트 포:레스트는 현재 부분적으로 개방 중으로 입장료나 예약 없이
            사용 가능합니다. 추후 정원의 전체 개방이 이뤄진 이후 이용에 대한
            자세한 안내를 드리도록 하겠습니다.
          </p>
          <p>
            현재 무료로 이용 가능한 공간은 식당, 카페, 모네의 정원, 신들의
            정원입니다. 자세한 안내는 종합 안내도를 참고해주세요.
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default VisitGuide;
