import { useState } from "react";
import { Link } from "react-router-dom";
import { AiFillCloseCircle } from "react-icons/ai";

const MobileHeader = ({ setToggle }) => {
  const [smallToggles, setSmallToggles] = useState({
    smallOne: false,
    smallTwo: false,
    smallThree: false,
  });

  const { smallOne, smallTwo, smallThree } = smallToggles;

  return (
    <div
      data-aos='fade-left'
      className={
        "h-100vh w-full md:w-96 fixed z-10 top-0 right-0 border-l border-neutral bg-stone-100 transition duration300 ease-in-out"
      }
    >
      <div className='bg-p p-3 text-base-100 text-right border-b border-solid border-base-200'>
        <button onClick={() => setToggle(false)}>
          <AiFillCloseCircle
            style={{ width: "2rem", height: "2rem", color: "#444" }}
          />
        </button>
      </div>
      <ul className='overflow-y-auto text-base-content '>
        <li
          tabIndex='0'
          onClick={() =>
            setSmallToggles({ ...smallToggles, smallOne: !smallOne })
          }
          className='outline-none w-full p-4 border-b border-solid border-base-200'
        >
          <span className='flex items-center justify-between text-slate-800 font-bold text-lg cursor-pointer active:bg-transparent active:font-bold'>
            Intro
          </span>
          <ul
            className={
              !smallOne ? "hidden" : "block  bg-stone-100 mt-4"
            }
          >
            <Link to='/about' >
              <li className='px-1 py-2 transition-all duration-300 ease-in-out active:bg-base-200 active:font-semibold active:text-primary '>정원소개</li>
            </Link>
            <Link to='/guidemap'>
              <li className='px-1 py-2 transition-all duration-300 ease-in-out active:bg-base-200 active:font-semibold active:text-primary '>종합안내도</li>
            </Link>
            <Link to='/theme'>
              <li className='px-1 py-2 transition-all duration-300 ease-in-out active:bg-base-200 active:font-semibold active:text-primary '>테마정원</li>
            </Link>
          </ul>
        </li>

        <li
          tabIndex='1'
          onClick={() =>
            setSmallToggles({ ...smallToggles, smallTwo: !smallTwo })
          }
          className='outline-none w-full p-4 border-b border-solid border-base-200'
        >
          <span className='flex items-center justify-between text-slate-800 font-bold text-lg cursor-pointer active:bg-transparent active:font-bold'>
            Program
          </span>
          <ul
            className={
              !smallTwo ? "hidden" : "block bg-stone-100 mt-4"
            }
          >
            <Link to='/memorytree'>
              <li className='px-1 py-2 transition-all duration-300 ease-in-out active:bg-base-200 active:font-semibold active:text-primary '>기억나무 심기</li>
            </Link>
            <Link to='/program'>
              <li className='px-1 py-2 transition-all duration-300 ease-in-out active:bg-base-200 active:font-semibold active:text-primary '>주요 서비스</li>
            </Link>
            <Link to='/reserve'>
              <li className='px-1 py-2 transition-all duration-300 ease-in-out active:bg-base-200 active:font-semibold active:text-primary '>방문예약</li>
            </Link>
          </ul>
        </li>

        <li
          tabIndex='2'
          onClick={() =>
            setSmallToggles({ ...smallToggles, smallThree: !smallThree })
          }
          className='outline-none w-full p-4 border-b border-solid border-base-200'
        >
          <span className='flex items-center justify-between text-slate-800 font-bold text-lg cursor-pointer active:bg-transparent active:font-bold'>
            Gu
          </span>
          <ul
            className={
              !smallThree ? "hidden" : "block bg-stone-100 mt-4"
            }
          >
            <Link to='/roadmap'>
              <li  className='px-1 py-2 transition-all duration-300 ease-in-out active:bg-base-200 active:font-semibold active:text-primary '>오시는 길</li>
            </Link>
            <Link to='/visit'>
              <li className='px-1 py-2 transition-all duration-300 ease-in-out active:bg-base-200 active:font-semibold active:text-primary '>이용안내</li>
            </Link>
          </ul>
        </li>
      </ul>
    </div>
  );
};

export default MobileHeader;
