import PageHeader from "../../Common/PageHeader";
import ThemeText from "../../Common/ThemeText";
import Layout from "../../Layout";
import styled from "styled-components";

const GardenImages = styled.picture`

    height: 300px;

    > source, img {
      height: 100%
    }

    @media screen and (max-width: 1024px) {
    height: 350px;
    }

        @media screen and (max-width: 480px) {
      height: 200px;
      }
  }
`;

const ThemeGarden = () => {
  return (
    <Layout>
      <div className={`w-full font-serif `}>
        <PageHeader PageTitle={"테마 정원"} />
        <div className=' px-6 pt-16 mb-16 text-lg text-gray-700 text-center '>
          레스트 포레스트에서만 만날 수 있는 테마 정원을 체험해보세요.
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 max-w-7xl mx-auto my-0 py-16">
          <article className='flex flex-col border-b'>
            <GardenImages
              data-aos='fade-zoom-in'
              data-aos-delay='100'
              data-aos-duration='1200'
              className='mx-auto my-0  border-base-300 lg:max-w-xl lg:min-w-sm'
            >
              <source
                media='(max-width: 768px)'
                width={"100%"}
                height={"350"}
                alt='모네의_정원'
                srcSet='/long1.jpg'
              />
              <source
                width={"100%"}
                height={"300"}
                alt='모네의_정원'
                srcSet='/long1.jpg'
              />
              <img
                srcSet='/long1.jpg'
                sizes='(max-width: 768px), 100% '
                alt='모네의_정원'
                width={"100%"}
                height={"300"}
              />
            </GardenImages>
            <ThemeText
              title={"모네의 정원"}
              content={`인상주의 대표 화가 클로드 모네가 남긴 250여
          편의 <수련> 연작을 한국적인 정서로 재현한 정원입니다. 모네는
          시시각각 변하는 빛을 표현하기 위해 수련이 떠 있는 연못의 물빛,
          수양버들의 흔들림, 물에 비친 구름 등이 담긴 장면을 많이 그렸습니다.
          올리브정원(가칭)에서 재현한 모네의 정원은 연못 주변으로
          심겨진 수양버들, 물위에 떠있는 수련과 거북이를 연상하는 자연석이
          조화를 이루어 다채로운 감상을 할 수 있습니다.`}
            />
          </article>

          <article className='flex flex-col border-b border-base-300 '>
            <GardenImages
              data-aos='fade-zoom-in'
              data-aos-delay='100'
              data-aos-duration='1200'
              className='mx-auto my-0 border-base-300 lg:max-w-xl lg:min-w-sm'
            >
              <source
                media='(max-width: 768px)'
                width={"100%"}
                height={"350"}
                alt='신들의_정원'
                srcSet='/long4.jpg'
              />
              <source
                width={"100%"}
                height={"300"}
                alt='신들의_정원'
                srcSet='/long4.jpg'
              />
              <img
                srcSet='/long4.jpg'
                sizes='(max-width: 768px), 100%'
                alt='신들의_정원'
                width={"100%"}
                height={"300"}
              />
            </GardenImages>
            <ThemeText
              title={"신들의 정원"}
              content={`자연석으로 조성된 신들의 정원에서는 역사적으로
          인류가 숭배해온 다양한 신들을 만나볼 수 있습니다. 오랜 시간 묵묵히
          한 자리를 지켜온 자연석은 인간에게 평화와 믿음을 주었던 신들의
          속성과 닮아있습니다. 계절마다 화려하게 피었다가 지는 꽃에 비하면 큰
          변화없이 생명력이 없어 보이는 돌이지만, 긴 시간의 흔적을 품고 있는
          하나 하나를 가만히 들여다 보면서 마음 속에 떠오르는 각자의 이야기에
          귀 기울여 보세요.`}
            />
          </article>
          <article className='flex flex-col  border-b border-base-300 '>
            <GardenImages
              data-aos='fade-zoom-in'
              data-aos-delay='100'
              data-aos-duration='1200'
              className='mx-auto my-0 border-base-300 lg:max-w-xl lg:min-w-sm'
            >
              <source
                media='(max-width: 768px)'
                width={"100%"}
                height={"350"}
                alt='가금류_수도원'
                srcSet='/long2.jpg'
              />
              <source
                width={"100%"}
                height={"300"}
                alt='가금류_수도원'
                srcSet='/long2.jpg'
              />
              <img
                srcSet='/long2.jpg'
                sizes='(max-width: 768px), 100%'
                alt='가금류_수도원'
                width={"100%"}
                height={"300"}
              />
            </GardenImages>
            <ThemeText
              title={"가금류 수도원"}
              content={`총 20여 종의 희귀한 닭이 자라고 있는
          체험존에서는 우리 나라에서 흔히 접할 수 없었던 다양한 모습의 닭을
          만나볼 수 있습니다. 닭 머리 위에 저마다 달고 있는 화려한 볏은 고대
          시대 인류가 자신의 권력을 드러내고 외모를 치장하는 방식에 영감을
          주었다는 것을 짐작할 수 있습니다. 달걀에서 닭으로 성장하고, 다시
          닭이 달걀을 낳는 순환 과정은 ‘부활’을 상징해왔습니다. 해당
          체험존에서도 달걀을 직접 수확해보면서 생명의 신비로움을 경험해볼 수
          있습니다.`}
            />
          </article>
          <article className='flex flex-col  border-b border-base-300 '>
            <GardenImages
              data-aos='fade-zoom-in'
              data-aos-delay='100'
              data-aos-duration='1200'
              className='mx-auto my-0 border-base-300 lg:max-w-xl lg:min-w-sm '
            >
              <source
                media='(max-width: 768px)'
                width={"100%"}
                height={"350"}
                alt='오래된_정원'
                srcSet='/long3.jpg'
              />
              <source
                width={"100%"}
                height={"300"}
                alt='오래된_정원'
                srcSet='/long3.jpg'
              />
              <img
                srcSet='/long3.jpg'
                sizes='(max-width: 768px), 100%'
                alt='오래된_정원'
                width={"100%"}
                height={"300"}
              />
            </GardenImages>

            <ThemeText
              title={"오래된 정원"}
              content={`50여년 전 자연을 사랑한 땅 주인이 깊은 산속에
          정성껏 가꿨던 목련나무 정원을 발견할 수 있습니다. 땅 주인이 돌아가신
          이후로 관리 되지 않은 채 오랜 시간 지나며 자연스럽게 주변에 번식한
          목련나무, 밤나무를 최대한 베어내지 않고 보존하여 조성한 야생
          정원에서는 인공적인 정원에서와 다른 자연의 정수를 경험하실 수
          있습니다.`}
            />
          </article>
          <article className='flex flex-col  border-b border-b border-base-300 '>
            <GardenImages
              data-aos='fade-zoom-in'
              data-aos-delay='100'
              data-aos-duration='1200'
              className='mx-auto my-0 border-base-300 lg:max-w-xl lg:min-w-sm'
            >
              <source
                media='(max-width: 768px)'
                width={"100%"}
                height={"350"}
                alt='좁은문_교회'
                srcSet='/long5.jpg'
              />
              <source
                width={"100%"}
                height={"300"}
                alt='좁은문_교회'
                srcSet='/long5.jpg'
              />
              <img
                srcSet='/long5.jpg'
                alt='좁은문_교회'
                sizes='(max-width: 768px), 100%'
                width={"100%"}
                height={"300"}
              />
            </GardenImages>

            <ThemeText
              title={"좁은문 교회"}
              content={`신들의 정원을 지나 꼭대기에 올라오면 만나는
          굽히는 교회는 사방으로 난 좁고 낮은 문을 통해 드나들 수 있도록 하여
          자연과 신 앞에 겸손한 태도를 취할 것을 요구합니다. 교회 안쪽에서
          바라보는 각각의 문은 일상, 종교, 자연 등 서로 다른 풍경을 담고 있어
          한 사람이 인생을 살아가면서 주어지는 선택지에 대해서 생각해볼 수
          있습니다.`}
            />
          </article>
        </div>
      </div>
    </Layout>
  );
};

export default ThemeGarden;
