import React, { useEffect } from "react";
import PageHeader from "../../Common/PageHeader";
import Layout from "../../Layout";
import { gsap } from "gsap";

const AllGuideMap = () => {
  useEffect(() => {
    gsap.to(".MapImage", { opacity: 1, duration: 3 });
  }, []);

  return (
    <Layout>
      <div className={`w-full  bg-base-100`}>
          <PageHeader PageTitle={"종합안내도"}/>
        <div
          className='w-full py-20 px-8 font-serif min-h-screen'
          data-aos='fade-zoom-in'
          data-aos-delay='300'
          data-aos-duration='1200'
        >
          <div className='w-full relative max-w-6xl opacity-0 MapImage my-0 mx-auto overflow-auto h-64 min-h-full border '>
            {/* <img
              width={1200}
              height={700}
              src={`/garden.jpg`}
              alt={`임시_이미지`}
              className='min-w-sm'
            /> */}
            <p className=' w-full h-full flex justify-center items-center text-center keepAll font-bold md:text-2xl lg:text-3xl'>여기에 종합 안내도가 들어갈 예정입니다.</p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AllGuideMap;
