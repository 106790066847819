import PageHeader from "../../Common/PageHeader";
import Layout from "../../Layout";
import styled from 'styled-components';

const Font15 = styled.p`
  font-size: 15px;
`

const AboutUs = () => {
  return (
    <Layout>
        <PageHeader PageTitle={"About us"}/>

      <div
        className='py-28 px-8 md:px-24 font-serif min-h-screen'
        data-aos='fade-zoom-in'
        data-aos-delay='300'
        data-aos-duration='1200'
      >
        <div className='space-y-2'>
          <h1 className='text-2xl font-bold'>세라비 정원</h1>
          <h4 className="text-xl font-semibold">Cestlavie Garden</h4>
        </div>
        <div className='max-w-5xl mt-8 space-y-8 keepAll'>
          <Font15>
            세라비 정원 정원에서는 자연과 진정한 연결을 맺어 나만의 속도와
            균형을 찾을 수 있습니다. 오랜 시간 생명력을 유지해온 자연과 교감을
            나누면서 일상에 새로운 영감을 더해보세요.
          </Font15>
          <Font15>
            50여년 전 자연을 사랑한 땅 주인이 깊은 산속에 정성껏 가꿨던 목련나무
            정원을 발견할 수 있습니다. 땅 주인이 돌아가신 이후로 관리 되지 않은
            채 오랜 시간 지나며 자연스럽게 주변에 번식한 목련나무, 밤나무를
            최대한 베어내지 않고 보존하여 조성한 야생 정원에서는 인공적인
            정원에서와 다른 자연의 정수를 경험하실 수 있습니다.
          </Font15>
          <Font15>
            자연석으로 조성한 신들의 정원, 사방으로 향한 낮은 문으로 들어가는
            굽히는 교회 등 자연 속에서 인간의 근원을 돌아보고 인생을 살아보는 건
            어떨까요?
          </Font15>
        </div>
      </div>
    </Layout>
  );
};

export default AboutUs;
