import Layout from "../../Layout";
import ReserveForm from "./ReserveForm";
import PageHeader from "../../Common/PageHeader";

const Reservation = () => {
  return (
    <Layout>
        <PageHeader PageTitle={'방문 예약'} />
      <div 
      className='w-full max-w-5xl py-20 px-8 md:p-24 font-serif min-h-screen'
      data-aos='fade-zoom-in'
      data-aos-delay='300'
      data-aos-duration='1200'
      >
        <div className='space-y-5 mb-12'>
          <p className='font-bold text-gray-700 border-b border-neutral border-solid pb-5 keepAll'>
            프리 오픈 기간 동안은 네이버 예약 및 카카오톡 오픈채팅을 통해
            프로그램 예약을 신청 받습니다.
          </p>
          <div className='font-semibold text-gray-700'>
            <p className="text-gray-700">
              <span className='text-red-600'>*</span> 네이버 예약하기 👉 {" "}
              <a href='/' target='_blank' rel='noopener noreferrer' className='underline decoration-solid'>
                바로가기
              </a>
            </p>
            <p className="text-gray-700">
              <span className='text-red-600'>*</span> 카카오톡 오픈 채팅 👉 {" "}
              <a href='/' target='_blank' rel='noopener noreferrer' className='underline decoration-solid'>
                바로가기
              </a>
            </p>
          </div>
          <p className="text-gray-700 keepAll">
            예약은 입금순으로 진행되며, 질문사항은 카카오톡 오픈채팅을 통해서
            문의 바랍니다.<br />
            <span className="">💳 입금처: OO은행 0000-0000-00000, 주식회사 사랑나무</span>
          </p>
        </div>
        <ReserveForm />
      </div>
    </Layout>
  );
};

export default Reservation;
