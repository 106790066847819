
const TreeCards = ({src, treeName, description, altText,ScrollDown}) => {
    return(
        <div onClick={ScrollDown} className="border border-solid border-neutral bg-base-200 text-neutral hover:bg-warning transition-all ease-in-out duration-300 p-5 flex flex-col justify-center items-center gap-4 cursor-pointer">
            <img src={src} width={'80'} height={'80'} alt={altText}/>
            <h4>{treeName}</h4>
            <hr/>
            <p>{description}</p>
        </div>
    )
}

export default TreeCards;